export const HOME_PAGE: string = '/patients'

export const MAX_TEXT_LENGTH: number = 200

export const PER_PAGE: number = 50
export const PAGE_SIZES: number[] = [10, 20, 30, 40, 50]

export const MAX_IMAGE_SIZE: number = 2048 * 1024
export const ACCEPTED_IMAGE_TYPES: string = '.jpg, .jpeg, .png, .gif, .svg files are accepted.'
export const ACCEPTED_IMAGE_MIME_TYPES: string[] = [
  'image/jpeg',
  'image/png',
  'image/jpg',
  'image/gif',
  'image/svg+xml',
]

export const MAX_FILE_SIZE: number = 102400 * 1024
export const ACCEPTED_FILE_TYPES: string =
  '.pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .jpeg, .png, .jpg, .gif, .svg, .zip, .rar, .dcm, .hl7, .xml, .nii, .nii.gz, .edf, .cdf, .mhd, .raw, .pic, .hdr, .img are accepted.'
export const ACCEPTED_FILE_MIME_TYPES: string[] = [
  'application/pdf',
  'application/msword', // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/vnd.ms-excel', // .xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
  'application/vnd.ms-powerpoint', // .ppt
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
  'image/jpeg',
  'image/png',
  'image/jpg',
  'image/gif',
  'image/svg+xml',
  'application/zip',
  'application/x-rar-compressed',
  'application/dicom', // .dcm
  'application/hl7', // .hl7
  'application/xml',
  'application/nii',
  'application/x-nii.gz',
  'application/edf',
  'application/cdf',
  'application/mhd',
  'application/octet-stream', // Generic binary for .raw, .pic, .hdr, .img (may need specific MIME types)
]
